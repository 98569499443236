/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'op2mise-react-widgets';
import MarketSpendFields from './MarketSpendFields';
import Button from '../../../shared/components/button/Button';
import {
  getTotalPercent,
  replaceWithInt,
  transformMarkets, transformWeeklySpends,
} from '../helpers/helpers';
import useStore from '../hooks/useCampaignStore';
import {marketSpendFormSchema, invalidTotal, invalidTotalMarket} from '../helpers/formSchema';
import Dialog from '../../Dialogs/Dialog';
import { updateMarketDistribution, notifyStations } from '../requests';
import useHandleUnsavedForm from '../hooks/useHandleUnsavedForm';
import UnsaveFormWarning from '../Dialog/UnsaveFormWarning';
import { preSubmitForm } from '../helpers/form';
import useSidenavStore from '../../../shared/sidebar/hooks/useSidenavStore';
import useDirtyForm from '../hooks/useDirtyForm';
import { useCallbackPrompt } from 'components/_reusable/blocker/UsePromptComponent.ts';
import ConfirmLeaveModal from 'shared/components/confirm-leave-modal/ConfirmLeaveModal';
import NotifyStationModal from '../Dialog/NotifyStationModal';

const MarketSpendForm = forwardRef(({}, ref) => {
  const {
    setShowCampaignForms,
    markets,
    setMarkets,
    isFormTouched,
    setIsFormTouched,
    setActiveFormId,
    selectedCampaign,
    setIsFormSaved,
    isFormSaved,
    changeFormTab,
    setChangeFormTab,
    setNewCampaign,
    newCampaign,
    currencyCode,
    setWeeklySpendDates,
    isCampaignActive,
    newCampaignSentNotification,
    setNewCampaignSentNotification,
  } = useStore((state) => state);
  const { toPath } = useSidenavStore((state) => state);
  const navigate = useNavigate();

  const [marketSpendList, setMarketSpendList] = useState(null);
  const [customErrorMsg, setCustomErrorMsg] = useState('');
  const [proceedAndSave, setProceedAndSave] = useState(false);

  // Modal States
  const [showDialog, setShowDialog] = useState(false);
  const [showStationNotificationModal, setShowStationNotificationModal] = useState(false);
  const [noActiveContactMessage, setNoActiveContactMessage] = useState('');
  const [isNotficationSending, setIsNotificationSending] = useState(false);

  useHandleUnsavedForm(setShowDialog);

  const { handleProceed, handleCancel, handleDiscard } = useDirtyForm({
    setShowDialog,
    setProceedAndSave,
  });

  // Dirty forms
  const [showConfirmLeaveModal, setShowConfirmLeaveModal] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation, handleHideNavigation] = useCallbackPrompt(
    isFormTouched
  );
  const [shouldExecuteSaveAndProceed, setShouldExecuteSaveAndProceed] = useState([false, () => {}]);
  const [proceedToNavigation, setProceedToNavigation] = useState(false);

  useImperativeHandle(ref, () => ({
    submitForm(callbackFunction) {
      setShouldExecuteSaveAndProceed([true, callbackFunction]);
    },
  }));

  useEffect(() => {
    if (!!markets && !!selectedCampaign) {
      setMarketSpendList(transformMarkets(markets, selectedCampaign));
    }
  }, [markets, selectedCampaign]);

  const handleNavigate = (proceed) => {
    if (!showConfirmLeaveModal && !shouldExecuteSaveAndProceed[0]) confirmNavigation();
    else {
      if (shouldExecuteSaveAndProceed[0]) {
        shouldExecuteSaveAndProceed[1]();
        setShouldExecuteSaveAndProceed([false, () => {}]);
      } else {
        setShowConfirmLeaveModal(false);
        if (proceed) handleClose(true);
      }
    }
  };

  // Market Spend Form Submission Logic
  const onSubmit = async (values, { setErrors }) => {
    const totalAllocation = Number(getTotalPercent(values.markets, 'allocation').toFixed(2));
    const maxTotalAllocation = 100.99;
    const minTotalAllocation = 99.00;

    if (totalAllocation < minTotalAllocation || totalAllocation > maxTotalAllocation) {
      const { errors, msg } = invalidTotalMarket(values.markets, 'allocation');
      setCustomErrorMsg(msg);
      setErrors({ markets: errors });
      return;
    }
    setCustomErrorMsg('');

    const data = {
      marketDistributionParams: values.markets.map((val) => ({
        campaignId: selectedCampaign.id,
        marketId: val.marketId,
        budget: '$',
        allocation: parseFloat(val.allocation || 0).toFixed(2),
        budgetAmount: parseFloat(val.budgetAmount || 0).toFixed(0),
        frequency: val.frequency || 0,
        reach: val.reach || 0,
        costPerPoint: parseFloat(val.costPerPoint || 0).toFixed(0),
        targetRating: parseFloat(val.targetRating || 0).toFixed(0),
      })),
    };

    const res = await updateMarketDistribution(data, selectedCampaign.id);
    if (res.statusCode === 200) {
      setIsFormSaved(true);
      setMarkets(res.value.marketDistributionResults);
      setWeeklySpendDates(
          transformWeeklySpends(res.value.weeklyDistributionResults),
      );

      // if (!newCampaignSentNotification) {
      //   setShowStationNotificationModal(true);
      // } else {
      //   setShowDialog(true);
      // }

      // Temporarily show campaign has been updated modal for now
      setShowDialog(true);

      setIsFormTouched(false);
      if (proceedToNavigation) setNewCampaign(false);
    } else {
      handleNavigate(false);
    }
    setIsFormSaved(true);
    setChangeFormTab(false);
  };

  // Market Spend Form Close Logic
  const handleClose = (proceedFromNavigation) => {
    if (isFormTouched && !proceedFromNavigation) {
      setShowConfirmLeaveModal(true);
    } else {
      setIsFormSaved(true);
      setIsFormTouched(false);
      setShowCampaignForms(false);
      setNewCampaign(false);
    }
  };

  // Modal Helper Functions
  const handleCloseDialog = () => {
    setShowDialog(false);
    setShowStationNotificationModal(false);
    setNoActiveContactMessage('');
    setNewCampaignSentNotification(true);
    if (proceedToNavigation) handleNavigate(true);
    if (newCampaign && !proceedToNavigation) setActiveFormId('weeklySpend');
  };

  const handleModalSendNotification = async () => {
    let notifyStationData = {
      campaignId: selectedCampaign.id,
      isNewCampaign: true,
    };

    setIsNotificationSending(true);
    try {
      const res = await notifyStations(notifyStationData, selectedCampaign.id)
      setShowStationNotificationModal(false);
      setChangeFormTab(newCampaign);
      setIsNotificationSending(false);
      setNewCampaignSentNotification(true);
      if (proceedToNavigation) handleNavigate(true);
      if (newCampaign && !proceedToNavigation) setActiveFormId('weeklySpend');
    } catch (error) {
      console.error(error);
      setShowStationNotificationModal(false);
      setIsNotificationSending(false);
      if (error.response.data.message && error.response.status === 400) {
        setNoActiveContactMessage(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    // If campaign is new, initiate dirty forms
    if (newCampaign) setIsFormTouched(true);
  }, [])

  return (
    <div className="d-flex w-100 gap-2 default-text" key={marketSpendList}>
      <Formik
        initialValues={{ markets: marketSpendList }}
        validationSchema={marketSpendFormSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          handleChange: onChange,
          setFieldValue,
          submitForm,
          touched,
          setTouched,
          validateForm,
          setErrors,
        }) => {
          const handleChange = (e) => {
            setTouched({ ...touched, [e.target.name]: e.target.value });
            setIsFormSaved(true);
            setIsFormTouched(true);
            setIsFormSaved(true);
            e.target.value = replaceWithInt(e.target.value);
            onChange(e);
          };

          const handleOnValidate = (proceedFromDirtyForm) => {
            preSubmitForm({
              setIsFormSaved,
              validateForm,
              setErrors,
              setChangeFormTab,
              setProceedAndSave,
              showConfirmLeaveModal,
              setShowConfirmLeaveModal,
              cancelNavigation,
              proceedFromDirtyForm,
              submitForm,
            });
          };

          const handleOnHide = () => {
            if (showConfirmLeaveModal) setShowConfirmLeaveModal(false);
            else cancelNavigation();
          };
          
          const handleOnDiscard = () => {
            if (showConfirmLeaveModal) handleClose(true);
            else {
              setNewCampaign(false);
              confirmNavigation();
            }
            setIsFormTouched(false);
            setProceedToNavigation(true);
          };
        
          const handleOnSaveAndProceed = () => {
            handleOnValidate(true);
            setProceedToNavigation(true);
            handleHideNavigation();
          };

          const handleClearMarketSpend = () => {
            if (!isCampaignActive) return;

            const updatedMarkets = values.markets.map(item => ({
              ...item,
              allocation: 0,
              budgetAmount: 0,
              targetRating: selectedCampaign?.objective === 1 ? item.targetRating : 0,
            }));
            setFieldValue('markets', updatedMarkets);
            setIsFormTouched(true);
          };

          useEffect(() => {
            if (shouldExecuteSaveAndProceed[0]) handleOnSaveAndProceed();
          }, [shouldExecuteSaveAndProceed]);

          return (
            <Form
              className="w-100 d-flex flex-column gap-2 form"
              noValidate
            >
              <NotifyStationModal
                show={showStationNotificationModal}
                content={
                  <p className="text-center">Campaign has been successfully created.<br/>
                  Would you like to notify the stations?</p>
                }
                onClose={handleCloseDialog}
                onProceed={handleModalSendNotification}
                isActionPending={isNotficationSending}
              />
              <Dialog
                show={noActiveContactMessage}
                onClose={handleCloseDialog}
              >
                {noActiveContactMessage}
              </Dialog>
              <Dialog
                onClose={handleCloseDialog}
                show={showDialog}
                Content={
                      changeFormTab && !isFormSaved ? (
                        <UnsaveFormWarning
                          handleProceed={() => handleProceed(submitForm, validateForm, setErrors)}
                          onCancel={handleCancel}
                          handleDiscard={handleDiscard}
                        />
                      ) : null
                    }
                isDirtyForm={changeFormTab && !isFormSaved}
              >
                Campaign {selectedCampaign?.name} has been updated.
              </Dialog>
              <div className="min-max-h-20 d-flex justify-content-center campagin-form customScroll">
                <div className="d-flex flex-column gap-10" style={{ width: '100%'}}>
                  <div className="d-flex gap-2 align-items-center" style={{ width: '100%'}}>

                    <div className="border-bottom d-flex gap-2" style={{ width: '99%'}}>
                      <div className="d-inline-flex" style={{ width: '100%'}}>
                        <div className="d-inline-flex gap-2 align-items-start" style={{ width: '100%'}}>
                        <div className="d-inline-flex" style={{ width: '30%'}}>
                        <span className="labelHeader">
                        Total market spend:
                          {' '}
                          {getTotalPercent(values.markets, 'allocation')}
                          %
                        </span>
                        </div>
                          <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
                            <span className="labelHeader">
                              %
                            </span>
                          </div>
                          <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
                            <span className="labelHeader">
                              Budget
                            </span>
                          </div>
                          <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
                            <span className="labelHeader">
                              Rating
                            </span>
                          </div>
                          <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
                            <span className="labelHeader">
                              Reach
                            </span>
                          </div>
                          <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
                            <span className="labelHeader">
                              CPP
                            </span>
                          </div>
                          <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
                            <span className="labelHeader">
                              Frequency
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex" style={{ width: '100%', marginTop: '20px'}}>
                    <div style={{ width: '30.3%'}}></div>

                    <div style={{ width: '69.7%'}}>
                      <span
                        className={
                          (!isCampaignActive)
                            ? 'clear-market-spend-button-disabled'
                            : 'clear-market-spend-button'
                        }
                        onClick={handleClearMarketSpend}
                      >
                        Clear all market spend
                      </span>
                    </div>
                  </div>

                  <div className="w-100 d-flex gap-2" style={{ marginTop: '-30px' }}>
                    {customErrorMsg && (
                        <span className="is-danger">{customErrorMsg}</span>
                    )}
                  </div>

                  <div className="d-flex flex-column gap-3 market-form-container mt-4 min-max-h-20 customScroll" style={{ width: '100%'}}>
                    {marketSpendList?.map((market, key) => (
                        <MarketSpendFields
                            key={`${market.distribution}-${key}`}
                            budget={selectedCampaign?.budget}
                            setFieldValue={setFieldValue}
                            index={key}
                            {...market}
                            values={values.markets}
                            errors={errors}
                            objective={selectedCampaign?.objective}
                            onChange={handleChange}
                            rating={selectedCampaign?.rating}
                            isCampaignActive={isCampaignActive}
                        />
                    ))}
                  </div>
                </div>
              </div>

              <div className="form-btn-container align-items-center d-flex gap-4 mt-5 justify-content-end" style={{ width: '95%' }}>
                <div>
                  <Button
                    text="Close"
                    onClick={() => handleClose(false)}
                    defaultBtn
                  />
                </div>
                <div>
                  <Button
                    text="Save"
                    type="submit"
                    onClick={() => handleOnValidate(false)}
                    disabled={!isCampaignActive}
                  />
                </div>
              </div>

              <ConfirmLeaveModal
                show={(showPrompt || showConfirmLeaveModal) && (!showStationNotificationModal && !showDialog)}
                onHide={handleOnHide}
                onSave={() => handleOnSaveAndProceed(handleOnValidate)}
                onDiscard={handleOnDiscard}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
})

export default MarketSpendForm;
